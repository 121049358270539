<template>
  <div class="home">
    <Jumbo/>
    <div class="container py-4">
      
      <div id="info">
        <div class="row my-2">
          <div class="col-lg-4 col-md-4 col-sm-12 p-4">
            <img src="@/assets/tiger.png" class="img-fluid" alt="orange black white tiger looking to the left illustration"/>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 p-4 py-5">
            <h2 class="fs-4">Wellsville Local Schools</h2>
            <hr>
            <div class="px-2">
              <div class="my-2">
                <i class="fas fa-building"></i>&nbsp; 1 Bengal Boulevard, Wellsville, Ohio 43968
              </div>
              <div class="my-2">
                <i class="fas fa-user"></i>&nbsp; Superintendent Richard Bereschik
              </div>
              <div class="my-2">
                <i class="fas fa-user"></i>&nbsp; Principal Richard Prescott
              </div>
              <div class="my-2">
                <i class="fas fa-user"></i>&nbsp; Athletic Director Don Elliott
              </div>
              <div class="my-2">
                <i class="fas fa-futbol"></i>&nbsp; Head Coach Cary Lemasters
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 p-4 py-5">
            <h2 class="fs-4">Recent News</h2>
            <hr>
            <div class="px-2">
              <div class="my-2">
                <a href="https://ohsaaweb.blob.core.windows.net/files/Sports/Soccer/SoccerRegs.pdf" target="_blank" rel="noopener">2022-23 OHSAA Soccer Regulations</a>
              </div>
              <div class="my-2">
                <a href="https://ohsaaweb.blob.core.windows.net/files/Sports/GeneralSportsRegulations.pdf" target="_blank" rel="noopener">2022-23 OHSAA General Sport Regulations</a>
              </div>
              <div class="my-2">
                <a href="https://www.ohsaa.org/medicine" target="_blank" rel="noopener">OHSAA Sports Medicine</a>
              </div>
              <div class="my-2">
                <a href="https://www.ohsaa.org/medicine/physicalexamform" target="_blank" rel="noopener">OHSAA Physical Exam Forms</a>
              </div>
              <div class="my-2">
                <a href="https://www.reviewonline.com/news/local-news/2019/03/wellsville-stadium-plans-laid-out/" target="_blank" rel="noopener">(2019) The Review - Nicholson Stadium Plans</a>
              </div>
              <div class="my-2">
                <a href="https://www.reviewonline.com/sports/local-sports/2017/08/wellsville-hosts-first-soccer-game/" target="_blank" rel="noopener">(2017) The Review - Wellsville Hosts First Game</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="map">
        <iframe width='100%' height='336px' id='mapcanvas' src='https://maps.google.com/maps?q=Nicholson%20Stadium,%20Wellsville,%20Ohio,%2043968&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=&amp;output=embed' frameborder='0' scrolling='no' marginheight='0' marginwidth='0'><div style='overflow:hidden;'><div id='gmap_canvas' style='height:336px;width:100%;'></div></div><div><small>Powered by <a href="https://www.embedgooglemap.co.uk">Embed Google Map</a></small></div></iframe>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Jumbo from '@/components/Jumbotron.vue'

export default {
  name: 'Home',
  components: {
    Jumbo
  },
  metaInfo: {
    title: "Wellsville Soccer - WHS Tiger Athletics in Wellsville, Ohio",
    meta: [
      { name: "author", content: "Github: @TheKicker" },
      {
        name: "description",
        content:
          "The official website of the Wellsville High School Soccer team in Wellsville, Ohio.  Member of the OHSAA, OVAC, and EOAC.  ",
      },
      {
        name: "keywords",
        content:
          "Wellsville, Ohio, WHS, Wellsville Tigers, OH, High School, HS, Tigers, Athletics, Soccer, Futbol, Football, Boys, Girls, Co-ed, OHSAA, OVAC, EOAC",
      },
      // OpenGraph data (Most widely used)
      { property: "og:title", content: "Wellsville Soccer - WHS Tiger Athletics in Wellsville, Ohio" },
      { property: "og:site_name", content: "Wellsville Soccer - WHS Tiger Athletics in Wellsville, Ohio" },
      // The list of types is available here: http://ogp.me/#types
      { property: "og:type", content: "website" },
      // Should the the same as your canonical link, see below.
      {
        property: "og:url",
        content: "https://wellsvillesoccer.com",
      },
      {
        property: "og:image",
        content: "https://wellsvillesoccer.com/wellsville-soccer-ogp.png",
      },
      // Often the same as your meta description, but not always.
      {
        property: "og:description",
        content:
          "The official website of the Wellsville High School Soccer team in Wellsville, Ohio.  Member of the OHSAA, OVAC, and EOAC.  ",
      },
      // Twitter card
      { name: "twitter:card", content: "summary" },
      {
        name: "twitter:site",
        content: "https://wellsvillesoccer.com",
      },
      { name: "twitter:title", content: "Wellsville Soccer - WHS Tiger Athletics in Wellsville, Ohio" },
      {
        name: "twitter:description",
        content:
          "The official website of the Wellsville High School Soccer team in Wellsville, Ohio.  Member of the OHSAA, OVAC, and EOAC.  ",
      },
      // Your twitter handle, if you have one.
      { name: "twitter:creator", content: "Github: @TheKicker" },
      {
        name: "twitter:image:src",
        content: "https://wellsvillesoccer.com/wellsville-soccer-ogp.png",
      },
      // Google / Schema.org markup:
      { itemprop: "name", content: "Wellsville Soccer - WHS Tiger Athletics in Wellsville, Ohio" },
      {
        itemprop: "description",
        content:
          "The official website of the Wellsville High School Soccer team in Wellsville, Ohio.  Member of the OHSAA, OVAC, and EOAC.  ",
      },
      {
        itemprop: "image",
        content: "https://wellsvillesoccer.com/wellsville-soccer-ogp.png",
      },
    ],
  },
}
</script>
<style scoped>

</style>