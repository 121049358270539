<template>
<div>
    <div id="desktop-only">
        <div id="jumbo" style="background: url('candid-1.jpg') center center/cover;">
            <div id="announcement" class="container rounded-lg">
                <div id="buffer" class="bg-light">
                    <h1 class="display-5">{{title}}</h1>
                    <p class="lead">{{message}}</p>
                    <router-link to="contact" class="btn btn-dark">{{button}}</router-link>
                </div>
            </div>
        </div>
    </div>
    <div id="mobile-only">
        <div class="parent">
            <img src="candid-1.jpg" class="img-fluid" alt="Wellsville HS Soccer players fight for the ball during a game at Nicholson Stadium in Wellsville, Ohio">
            <div id="buffer" class="bg-light">
                    <h1 class="display-5">{{title}}</h1>
                    <p class="lead">{{message}}</p>
                    <router-link to="contact" class="btn btn-dark">{{button}}</router-link>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'Jumbo',
  data: function () {
    return {
      title: "WHS Soccer is on hold",
      message: "Unfortunately, due to COVID, we did not have enough interest to field a team for this upcoming season. If you or anyone you know would like to play in the future, we need at least 15 players to field a roster.  Hopefully we can return to the pitch with you soon! ",
      button: "Contact Us"
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#jumbo{
    width: 100%;
    max-width: 1280px;
    margin: auto;
}
#announcement{
    padding: 6rem 4rem;
}
#buffer{
    margin: 6rem 0;
    padding: 4rem;
    opacity: 0.9;
}
.parent{
    display: flex; 
    flex-direction: column;
}
@media only screen and (max-width: 825px) {
    #announcement{
        padding: 2.5rem;
    }
    #buffer{
        margin: 0;
        padding: 1rem;
        opacity: 0.89;
        outline: 1px black solid;
    }
}
</style>
